import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import {graphql, useStaticQuery} from 'gatsby';
import Qm from "../../layouts/QM";


const QmEn = () => {
  const data = useStaticQuery(graphql`
    query qmDatasEn {
      image: file(relativePath: { eq: "qm/header.jpg"}) {
        id
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      allContentfulAvantagesGfa(sort: {fields: createdAt, order: ASC}, filter: {node_locale: {eq: "en-CA"}}) {
        edges {
          node {
            name
            functionalities {
              functionalities
            }
            gif {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
      allContentfulInstallationsGfa {
        edges {
          node {
            installations {
              gatsbyImageData(layout: CONSTRAINED)
            }
            clients {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `)
  return (
    <Layout 
    lang="en-CA" 
    switchLink='/gestion-de-file-dattente'
    slider={true}
    headerImage={data.image.childImageSharp} 
    title="Discover our complete solution for queue mangement" 
    subTitle="We carry out tailor-made projects and we personalize your queue management according to your needs." 
    anchorLink="#touch-projects-types"
    anchorLinkText="Learn more">
      <Seo title="Interactive Digital Signage Kiosks | Digital Signage Stands"
           description="Our interactive digital signage kiosk includes geotracking, queue management, secure environment, interactive features, and games. Touch-Screen digital signage solutions personalized to your needs."
           lang="en-CA"/>
      <Qm lang="en-CA" types={data.allContentfulAvantagesGfa} carouselDatas={data.allContentfulInstallationsGfa.edges[1].node}/>
    </Layout>
  )
}

export default QmEn
